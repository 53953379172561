<template>
  <ProductForm
    @saveDetail="saveCustomProduct"
    :productDetail="productDetail"
    :update="false"
  />
</template>
  
  <script>
import ProductForm from "./CustomProductForm.vue";
import { mapActions } from "vuex";
import OrganizationHelper from "@/mixins/Organization";

export default {
  name: "AddCustomProduct",
  components: {
    ProductForm,
  },
  data: () => ({
    productDetail: {
      name: "",
      unit: "",
      price: 0,
      sku: null,
      applicableAreas: [],
      description: "",
      customApplicableAreas: [],
    },
    role: null,
    organizationId: null,
  }),
  mixins: [OrganizationHelper],
  methods: {
    ...mapActions("product", ["createCustomProduct", "assignCustomTreatmentAreas"]),
    async saveCustomProduct(data, selectedTreatmentType, treatmentTypesOption, customTreatmentAreas) {
      try {
        this.$vs.loading();
        data.treatmentType = selectedTreatmentType;
        data.organizationId = this.organizationId;
        delete data.customApplicableAreas;
        const res = await this.createCustomProduct(data);
        if (res.status == 200) {
          if (customTreatmentAreas.length) {
            await this.assignCustomTreatmentAreas({
              id: res.data.data._id,
              payload: {
                organizationId: this.organizationId,
                customTreatmentAreas,
                isDefault: false, // Always a custom product in this scenario
              },
            });
          }
          this.$vs.notify({
            title: "Product Add",
            text: "Product Detail added successfully",
            color: "success",
          });
          this.$vs.loading.close();
          this.$router.push({ name: this.getRoleRouteName("CustomProductsList") });
        }
      } catch (err) {
        this.$vs.notify({
          title: "Error",
          text: err.data.message,
          color: "danger",
        });
        this.$vs.loading.close();
      }
    },
  },
  created() {
    this.role = localStorage.getItem(
      `${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`
    );
    this.organizationId = localStorage.getItem(
      `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
    );
  },
};
</script>
  
  <style scoped></style>
  